<template>
  <div class="main">
    <tabs v-model="activeName">
      <tab-pane :label="$t('All quotes')" name="all">
      </tab-pane>
    </tabs>
    <div class="filter">
      <div class="item cl">
        <div class="label">{{$t('Issue date')}}：</div>
        <div class="fl">
          <el-date-picker class="select"
                          v-model="issueDate"
                          type="date"
                          size="small"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('PleaseChoose')">
          </el-date-picker>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Deadline date')}}：</div>
        <div class="fl">
          <el-date-picker class="select"
                          v-model="deadlineDate"
                          type="date"
                          size="small"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('PleaseChoose')">
          </el-date-picker>
        </div>
      </div>
      <button class="button button-primary small m-r" type="button" @click="getList()">{{$t('search')}}</button>
      <button class="button small" type="button" @click="clearDate">{{$t('Clear')}}</button>
      本月已报价数量：{{ quoteAndMember.adminQuoteCount }}/{{ quoteAndMember.adminMemberCount }}
      升级会员等级，可提高更多报价
    </div>
    <div class="table">
      <table>
        <thead>
        <tr>
          <th class="is-sortable" :class="sortClassName('quoteNumber')" @click="sortable('quoteNumber')">
            <span class="vam">{{$t('Quote number')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
            </span>
          </th>
          <th>{{$t('Quote name')}}</th>
          <th class="is-sortable" :class="sortClassName('startDate')" @click="sortable('startDate')">
            <span class="vam">{{$t('Issue date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
            </span>
          </th>
          <th class="is-sortable" :class="sortClassName('endDate')" @click="sortable('endDate')">
            <span class="vam">{{$t('Deadline date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
            </span>
          </th>
          <th class="is-sortable" :class="sortClassName('status')" @click="sortable('status')">
            <span class="vam">{{$t('Status')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
            </span>
          </th>
          <th class="is-sortable" :class="sortClassName('result')" @click="sortable('result')">
            <span class="vam">{{$t('QuoteStatus')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
            </span>
          </th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) of list" :key="item.quoteId">
          <td>{{item.numbering}}</td>
          <td>
            <router-link class="title" target="_blank"
                         :to="{name:'AdminQuoteDetail',params: {id:item.quoteId}}">{{item.title}}
            </router-link>
          </td>
          <td>{{item.issueDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.deadlineDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.isStatus|quoteStatus}}</td>
          <td>{{(item.joinResult||'unjoin')|dictName('quoteStatus')}}</td>
          <td>
            <router-link class="btn-item" target="_blank" :to="{name:'AdminQuoteDetail',params: {id:item.quoteId}}">
              <img src="../../../assets/images/icon/review.png"/>
            </router-link>
            <!--<a class="btn-item" href="javascript:;">
              <img src="../../../assets/images/icon/pen.png"/>
            </a>-->
            <!-- 隐藏商家删除报价按钮
            <a class="btn-item" href="javascript:;" @click="deleteQuote(index,item.quoteId)" v-if="item.joinResult">
              <img src="../../../assets/images/icon/ashcan.png"/>
            </a>
            -->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages txt-r"
                   layout="sizes, prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   @size-change="handleSizeChange"
                   :hide-on-single-page="false">
    </el-pagination>
  </div>
</template>
<script>
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Empty from '../../../components/Empty';
  import table from '../../../mixins/table';
  import {scrollToY} from '../../../utils';
  import {getQuoteList, deleteQuote, getMemberQuoteCount} from '../../../api/admin-quote';

  export default {
    name: 'AdminQuote',
    data() {
      return {
        activeName: 'all',
        perPage: 10,
        list: [],
        totalPages: 1,
        isEmpty: false,
        issueDate: '',
        deadlineDate: '',
        quoteAndMember: {
          adminMemberCount: 10,
          adminQuoteCount: 0
        }
      };
    },
    components: {Empty, Tabs, TabPane},
    mixins: [table],
    created() {
      this.getList();
      this.getMemberQuoteCount();
    },
    methods: {
      getList(page = 1) {
        const data = {page, perPage: this.perPage, issueDate: this.issueDate, deadlineDate: this.deadlineDate};
        getQuoteList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.$nextTick(() => {
            scrollToY();
          });
        });
      },
      // 获取本月报价数量
      getMemberQuoteCount() {
        getMemberQuoteCount().then(res => {
          const {value} = res;
          this.quoteAndMember = value;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      handleSizeChange(val) {
        this.perPage = val;
        this.getList();
      },
      // 删除报价
      deleteQuote(index, quoteId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: '确定要删除已参与的报价吗？',
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteQuote(quoteId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list[index].joinResult = null;
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 清除参数
      clearDate() {
        this.deadlineDate = '';
        this.issueDate = '';
        this.getList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    /deep/ .tab-nav{margin-top:0;}
    /deep/ .table{
      th,
      td{padding:9px 8px;}
      .title{display:block;width:100px;text-decoration:underline;color:#ff6f61;cursor:pointer;}
      .btn-item{margin-right:16px;}
    }
  }
  .filter{
    margin-top:24px;
    .item{
      display:inline-block;margin-right:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    /deep/ .select{width:140px;vertical-align:top;}
  }
</style>
